
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import style from './index.module.css'
import formStyle from '../../styles/form.module.css'

import { getLayout, LayoutContext } from '../../components/sg/Layout'
import useTranslation from 'next-translate/useTranslation'
import classNames from 'classnames/bind'
import { useState, useEffect, useContext, useRef } from 'react'
import Cards from '../../components/sg/Cards'
import Footer from '../../components/sg/Footer'
import Image from 'next/image'
import Link from 'next/link'
import Logo from '../../components/Logo'
import Router, { useRouter } from 'next/router'
import { defaultCurrency, useLivePrice, usePremiums } from '../../hooks/usePrices'
import NumberCount from '../../components/NumberCount'
import { sp500, uraResidential, gold, meta } from '../../helpers/economicData'
import Explorer from '../../components/Explorer'
import { historicGain } from '../../helpers/historicGain'
import useUser from '../../hooks/useUser'
import { event } from '../../helpers/analytics'
import Loading from '../../components/Loading'

let timer

 async function _getServerSideProps ({ req: { headers: { 'x-vercel-ip-country': ipCountry } } }) {
  return { props: { ipCountry: ipCountry || 'SG' } }
}

export default function Index ({ ipCountry }) {
  const { t } = useTranslation('main')
  const c = classNames.bind(style)
  const cf = classNames.bind(formStyle)

  const router = useRouter()
  const { locale } = router
  const lang = locale.split('-')[0]
  const langExtension = lang !== 'en' ? '-' + lang : ''

  const [now, setNow] = useState(new Date())
  const [startYear, setStartYear] = useState(2001)
  const [period, setPeriod] = useState(new Date().getFullYear() - 2001 + 1)
  const [amount, setAmount] = useState(10000)
  const [sendStatus, setSendStatus] = useState('')

  const formElem = useRef()
  const nameElem = useRef()
  const emailElem = useRef()
  const messageElem = useRef()

  const filterFunc = (series, add) => series.filter(s => s.year >= startYear && s.year < startYear + period).reduce((a, v) => a * (v.value / 100 + 1), amount) - amount
  const sp500Value = filterFunc(sp500)
  const uraResidentialValue = filterFunc(uraResidential, true)
  const goldValue = filterFunc(gold)
  const minValue = Math.min(sp500Value, uraResidentialValue, goldValue, 0)
  const maxValue = Math.max(sp500Value, uraResidentialValue, goldValue, 0)
  const percent = (maxValue - minValue) / 180
  const basePercent = Math.max(-minValue / percent, 0)
  const sp500Percent = Math.abs(sp500Value) / percent
  const uraResidentialPercent = Math.abs(uraResidentialValue) / percent
  const goldPercent = Math.abs(goldValue) / percent
  const sp500Top = sp500Value < 0 ? basePercent - sp500Percent : basePercent
  const uraResidentialTop = uraResidentialValue < 0 ? basePercent - uraResidentialPercent : basePercent
  const goldTop = goldValue < 0 ? basePercent - goldPercent : basePercent

  const [layoutContext, setLayoutContext] = useContext(LayoutContext)
  const { currency } = layoutContext
  const { price: { value: spotPrice }, spotFx } = useLivePrice(currency, 'gld')
  const { gain: centuryGain, pastDate } = historicGain(spotPrice, spotFx)
  const premiums = usePremiums(currency, locale)
  const lowestPremium = Object.entries(premiums).reduce((acc, [type, { buy }]) => {
    // Convert the percentage string to a number
    const buyPremiumValue = parseFloat(buy.replace('%', '')) / 100

    return buyPremiumValue < acc.value ? { type, value: buyPremiumValue } : acc
  }, { type: null, value: Number.POSITIVE_INFINITY })

  const { user, account } = useUser()
  const emails = user?.Contacts.filter(c => c.Type === 'Email') || []
  const email = emails.find(c => c.IsDefault)?.Value || emails[0]?.Value || ''

  useEffect(() => {
    console.log('3D CSS phone art based on work from Grzegorz Witczak: https://codepen.io/Wujek_Greg/pen/LmrweG')
    timer = setInterval(() => setNow(new Date()), 1000)
    return () => clearInterval(timer)
  }, [])

  const handleInstallClick = e => {
    if (window.isAndroid && window.deferredInstallPrompt) {
      e.preventDefault()
      e.stopPropagation()
      Router.push('/install')
    }
  }

  const handleSupportSubmit = e => {
    e.preventDefault()
    formElem.current.removeAttribute('novalidate')
    if (!formElem.current.reportValidity()) {
      return
    }

    event('conversion', 'lR9mCIzlzJ0ZEOLP0NID')

    setSendStatus('sending')

    window.fetch('/api/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: nameElem.current.value.trim(),
        email: emailElem.current.value.trim(),
        message: messageElem.current.value.trim(),
        username: user?.UserName,
        account: account?.AccountId,
        source: `stargrams.app${window.location.pathname}`
      })
    }).then(res => {
      if (res.status !== 200) {
        throw new Error('API not available')
      }
      setLayoutContext({ success: t('support-form-sent') })
      setSendStatus('sent')

      formElem.current.setAttribute('novalidate', true)
      formElem.current.reset()
    }).catch(e => {
      setLayoutContext({ message: t('support-form-error') })
      setSendStatus('error')
    })
  }

  return (
    <>
      <section className={c('homescreen')}>
        <div className={c('logo')}>
          <Logo width={52} />
          <h1>{t('sghome-starsaver')}<span>{t('sghome-starsaver2')}</span></h1>
        </div>
        <div className={c('maininfo')}>
          <h3>{t('sghome-starsaver-title-1')}<br />{t('sghome-starsaver-title-2')}</h3>
          <p>
            {t('sghome-starsaver-slogan1')}
            <br />
            {t('sghome-starsaver-slogan2')}
          </p>
          <div className={c('promo')}>
            <Link href='/signup'>
              <button
                className='goldenshimmer'
                onClick={handleInstallClick}
                title={t('sghome-startsaving-button-title')}
              >
                {t('sghome-startsaving-button')}
              </button>
            </Link>
            {ipCountry === 'SG' && <div className={c('singpass')}><Link href='/signup'><a>{t('sghome-signupwithsingpass-before')} <img src='/images/singpass.svg' width='68' alt='singpass' /> {t('sghome-signupwithsingpass-after')}</a></Link></div>}
            <div className={c('subtext')}>
              <img src='/images/bars-400px.png' alt={t('sghome-goldbar-alt')} />
              <div>
                <b>{t('sghome-startsaving-promo', { lowestPremium: lowestPremium.value.toLocaleString(locale, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) })}</b>
                <br />
                {t('sghome-startsaving-tease')}
              </div>
            </div>
          </div>
        </div>

        <div className={c('partnerlogos')}>
          <span>{t('sghome-backedby')}</span>
          <a href='https://silverbullion.com.sg' target='_blank' rel='noopener noreferrer'>
            <Image src='/images/silver-bullion-logo.svg' width={130} height={42} alt='Silver Bullion' />
          </a>
          <a href='https://www.aspial.com' target='_blank' rel='noopener noreferrer'>
            <Image src='/images/aspial-logo.png' width={90} height={41} alt='Aspial' />
          </a>
        </div>

        <div className={c('phone')}>
          <div className={c('phone-back')}>
            <div className={c('phone-left-side')}>
              <div className={c('phone-antena')} />
              <div className={c('phone-button', 'top')} />
              <div className={c('phone-button')} />
              <div className={c('phone-button', 'bottom')} />
              <div className={c('phone-antena', 'bottom')} />
            </div>
            <div className={c('phone-bottom')}>
              <div className={c('phone-antena')} />
              <div className={c('bottom-speaker')}>
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
              </div>
              <div className={c('phone-screw')}>
                <div />
              </div>
              <div className={c('phone-charger')} />
              <div className={c('phone-screw', 'right')}>
                <div />
              </div>
              <div className={c('bottom-speaker', 'right')}>
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
              </div>
              <div className={c('phone-antena', 'right')} />
            </div>
          </div>
          <div className={c('phone-screen')} />
          <div className={c('phone-display')}>
            <div className={c('phone-notch')}>
              <div className={c('phone-speaker')} />
            </div>
            <div className={c('display-content')}>
              <div className={c('notifications-bar')}>
                <div className={c('time')}>{now.toLocaleTimeString(locale, { hour: 'numeric', minute: '2-digit', hour12: false })}</div>
                <div className={c('range')} />
                <div className={c('wifi')} />
                <div className={c('battery')} />
              </div>
              <iframe className={c('content')} src='./price' scrolling='no' />
              <div className={c('home-button')} />
            </div>
          </div>
          <div className={c('phone-reflections')}>
            <div className={c('reflection-1')} />
            <div className={c('reflection-2')} />
            <div className={c('reflection-3')} />
            <div className={c('reflection-4')} />
            <div className={c('reflection-5')} />
            <div className={c('reflection-6')} />
            <div className={c('reflection-7')} />
          </div>
        </div>

        <div className={c('bubble')}>
          <span>{t('sghome-goldgainbubble-historically')}</span>
          {centuryGain && <span className={c('big')}>{(centuryGain * 100).toLocaleString(locale, { minimumFractionDigits: 0, maximumFractionDigits: 1 })}</span>}
          <span>{t('sghome-goldgainbubble-onaverageperyearsince', { year: pastDate.getFullYear() })}</span>
        </div>
      </section>

      <section className={c('explorer')}>
        <Explorer />
      </section>

      <section className={c('video')}>
        <div>
          <video poster={`/images/intro_poster${langExtension}.jpg`} controls>
            <source src={`/assets/starsavingsaccount_intro${langExtension}.mp4`} type='video/mp4' />
            <div className={c('videofallback')}>
              {t('sghome-noembedvideo')}
              <br /><br />
              <a href={`/assets/starsavingsaccount_intro${langExtension}.mp4`} download>{t('sghome-noembedvideo-download')}</a>
            </div>
          </video>
        </div>
        {t('sghome-introvideo-description')}
        <br /><br />
        <Link href='/signup'><button className='goldenshimmer'>{t('sghome-introvideo-startsaving-button')}</button></Link> <Link href='/sg/tour'><button className='light'>{t('sghome-introvideo-takeatour')}</button></Link>
      </section>

      <section className={c('performance')}>
        <div>
          <h3>{t('sghome-historicgains-header')}</h3>
          <p>{t('sghome-historicgains-description')}</p>
          <div className={c('savingsChart')}>
            <div className={c('zeroline')} style={{ bottom: basePercent + 'px' }} />
            <div id='uraResidential' className={c('savingsBar', { up: uraResidentialValue > 0 })} style={{ height: uraResidentialPercent + 'px', marginBottom: uraResidentialTop + 'px' }}>
              <span><NumberCount significantDigits={2} number={uraResidentialValue} options={{ style: 'currency', currency: defaultCurrency, currencyDisplay: 'symbol' }} /></span>
            </div>
            <div id='sp500' className={c('savingsBar', { up: sp500Value > 0 })} style={{ height: sp500Percent + 'px', marginBottom: sp500Top + 'px' }}>
              <span><NumberCount significantDigits={2} number={sp500Value} options={{ style: 'currency', currency: defaultCurrency, currencyDisplay: 'symbol' }} /></span>
            </div>
            <div id='gold' className={c('savingsBar', 'gold', { up: goldValue > 0 })} style={{ height: goldPercent + 'px', marginBottom: goldTop + 'px' }}>
              <span><NumberCount significantDigits={2} number={goldValue} options={{ style: 'currency', currency: defaultCurrency, currencyDisplay: 'symbol' }} /></span>
            </div>
          </div>
          <div className={c('savingsLabels')}>
            <label htmlFor='uraResidential'>{t('sghome-savings-residentialproperty')}</label>
            <label htmlFor='sp500'>{t('sghome-savings-sandp500')}</label>
            <label htmlFor='gold'>{t('sghome-savings-gold')}</label>
          </div>
          <div className={c('savingsControl')}>
            <div>
              <label htmlFor='startYear'>{t('sghome-historicgains-startyear-label')}:</label> <output>{startYear} - {meta.maxYear + 1}</output>
              <input type='range' min={meta.minYear} max={meta.maxYear} value={startYear} onChange={e => { setStartYear(parseInt(e.target.value, 10)); setPeriod(meta.maxYear - parseInt(e.target.value, 10) + 1) }} id='startYear' />
            </div>
            <div>
              <label htmlFor='amount'>{t('sghome-historicgains-investmentamount-label')}:</label> <output><NumberCount significantDigits={2} number={amount} options={{ style: 'currency', currency: defaultCurrency, currencyDisplay: 'symbol' }} /></output>
              <input type='range' min={2000} max={500000} step={2000} value={amount} onChange={e => setAmount(parseInt(e.target.value, 10))} id='amount' />
            </div>
          </div>
          <div className={c('sourcenote')}>{t('sghome-historygains-source')}</div>
        </div>
      </section>

      <section className={c('benefits')}>
        <div>
          <h3>{t('sghome-whyuse-header')}</h3>
          <div className={c('split')}>
            <ul>
              <li>
                <div>
                  <img src='/images/app.svg' width={48} height={48} alt='Instant liquidity' />
                  <h4>{t('sghome-instantliquidity-title')}</h4>
                </div>
                <p>{t('sghome-instantliquidity-body')}</p>
              </li>
              <li>
                <div>
                  <img src='/images/licensed.svg' width={48} height={48} alt='Licensed dealer' />
                  <h4>{t('sghome-licensed-title')}</h4>
                </div>
                <p>{t('sghome-licensed-body')}<br />{/* We are <a href='/assets/GSA-Shariah-Compliance.pdf' target='_blank' rel='noopener noreferrer'>certified Shariah compliant</a>. */}</p>
              </li>
            </ul>
            <ul>
              <li>
                <div>
                  <img src='/images/deposits.svg' width={48} height={48} alt='Easy transactions' />
                  <h4>{t('sghome-multicurrency-title')}</h4>
                </div>
                <p>{t('sghome-multicurrency-body')}</p>
              </li>
              <li>
                <div>
                  <img src='/images/technology.svg' width={48} height={48} alt='Secured connection' />
                  <h4>{t('sghome-encrypted-title')}</h4>
                </div>
                <p>{t('sghome-encrypted-body')}<br /><br /></p>
              </li>
            </ul>
          </div>
          <Link href='/signup'>
            <button
              className='goldenshimmer'
              onClick={handleInstallClick}
              title={t('sghome-startsaving-button-title')}
            >
              {t('sghome-startsaving-button')}
            </button>
          </Link>

          {/* Contact form */}
          <form noValidate ref={formElem} id='supportform' onSubmit={handleSupportSubmit} className={c('support')}>
            <h3>{t('support-form-title')}</h3>
            <p>{t('support-form-body')}</p>
            <div className={c('fields')}>
              <div className={c('row')}>
                <div className={cf('inputfield')}>
                  <label htmlFor='support_name'>{t('support-name-label')}</label>
                  <input type='text' key={user ? user.FirstName + ' ' + user.LastName : ''} disabled={!!user} defaultValue={user ? user.FirstName + ' ' + user.LastName : ''} ref={nameElem} id='support_name' autoComplete='name' required minLength={2} placeholder={t('support-name-placeholder')} onChange={e => { e.target.setCustomValidity('') }} />
                </div>
                <div className={cf('inputfield')}>
                  <label htmlFor='support_email'>{t('support-email-label')}</label>
                  <input type='email' key={email} defaultValue={email} disabled={!!email} ref={emailElem} id='support_email' autoComplete='email' required placeholder={t('support-email-placeholder')} onChange={e => { e.target.setCustomValidity('') }} />
                </div>
              </div>
              <div className={cf('inputfield')}>
                <label htmlFor='support_message'>{t('support-message-label')}</label>
                <textarea ref={messageElem} id='support_email' rows={5} required placeholder={t('support-message-placeholder')} onChange={e => { e.target.setCustomValidity('') }} />
              </div>
              <button type='submit' disabled={sendStatus === 'sending'}>{sendStatus !== 'sending' ? t('support-sendmessage-button') : <Loading />}</button>
            </div>
          </form>
        </div>
      </section>

      <Cards />

      <Footer />
    </>
  )
}

Index.getLayout = getLayout


    export async function getServerSideProps(ctx) {
        let res = _getServerSideProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/sg/index',
              loaderName: 'getServerSideProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  
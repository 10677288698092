export default function LangHeaders ({ locales, defaultLocale, asPath, pathname, withExtension = false }) {
  const siteUrl = 'https://www.stargrams.app'
  const canonicalUrl = siteUrl + asPath
  const defaultPath = withExtension ? pathname.replace(/-[a-z]{2}$/, '') : pathname
  const defaultUrl = siteUrl + defaultPath
  console.log(pathname, locales, asPath, withExtension)

  return (
    <>
      <link rel='canonical' key='canonical' href={canonicalUrl} />
      <link rel='alternate' key='x-default' hrefLang='x-default' href={defaultUrl} />
      {locales?.map(l => {
        const lang = l.split('-')[0]
        const langExtension = lang !== 'en' ? '-' + lang : ''
        const url = siteUrl + (l === defaultLocale ? '' : '/' + l) + defaultPath + (withExtension ? langExtension : '')
        return <link rel='alternate' key={'alternate-' + l} hrefLang={l} href={url} />
      })}
    </>
  )
}
